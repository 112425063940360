:root {
    /* Spacing sizes (for mostly max widths) */
    --size-xs: 20rem; /* 320px */
    --size-sm: 24rem; /* 384px */
    --size-md: 28rem; /* 448px */
    --size-lg: 32rem; /* 512px */
    --size-xl: 36rem; /* 576px */
    --size-2xl: 42rem; /* 672px */
    --size-3xl: 48rem; /* 768px */
    --size-4xl: 56rem; /* 896px */
    --size-5xl: 64rem; /* 1024px */
    --size-6xl: 72rem; /* 1152px */
    --size-7xl: 80rem; /* 1280px */

    /*Border radius scale */
    --rounded-sm: 0.125rem; /* 2px */
    --rounded: 0.25rem; /* 4px */
    --rounded-md: 0.375rem; /* 6px */
    --rounded-lg: 0.5rem; /* 8px */
    --rounded-xl: 0.75rem; /* 12px */
    --rounded-2xl: 1rem; /* 16px */
    --rounded-3xl: 1.5rem; /* 24px */
    --rounded-full: 9999px;

    /* Shadows */
    --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --shadow-2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --shadow-inner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --shadow-none: 0 0 #0000;


    /* Animations */
    --animate-pulse: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
}