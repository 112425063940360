:root {
    --fs-sm: clamp(0.8rem, 0.17vi + 0.76rem, 0.89rem);
    --fs-base: clamp(1rem, 0.34vi + 0.91rem, 1.19rem);
    --fs-md: clamp(1.25rem, 0.61vi + 1.1rem, 1.58rem);
    --fs-lg: clamp(1.56rem, 1vi + 1.31rem, 2.11rem);
    --fs-xl: clamp(1.95rem, 1.56vi + 1.56rem, 2.81rem);
    --fs-xxl: clamp(2.44rem, 2.38vi + 1.85rem, 3.75rem);
    --fs-xxxl: clamp(3.05rem, 3.54vi + 2.17rem, 5rem);
}

.fs-sm {
    font-size: var(--fs-sm);
}

.fs-base {
    font-size: var(--fs-base);
}

.fs-md {
    font-size: var(--fs-md);
}

.fs-lg {
    font-size: var(--fs-lg);
}

.fs-xl {
    font-size: var(--fs-xl);
}

.fs-xxl {
    font-size: var(--fs-xxl);
}

.fs-xxxl {
    font-size: var(--fs-xxxl);
}
